import '@mdi/font/css/materialdesignicons.css'
// Styles
import "vuetify/styles";
// Composables
import { createVuetify } from "vuetify";

import * as directives from "vuetify/directives";


export default createVuetify({
    icons: {
        iconfont: 'mdi',
    },
    defaultAssets: false,
    directives
});