import { createWebHistory, createRouter } from "vue-router";
import LoginComponent from "@/components/LoginComponent.vue";
import StoreComponent from "@/components/StoreComponent.vue";
import ItemDetailComponent from "@/components/ItemDetailComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import RegistrationComponent from "@/components/RegistrationComponent.vue";
import HomeComponent from "@/components/HomeComponent.vue";
import FaqComponent from "@/components/FaqComponent.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeComponent,
    },
    {
        path: "/faq",
        name: "Faq",
        component: FaqComponent,
    },
    {
        path: "/login",
        name: "Login",
        component: LoginComponent,
    },
    {
        path: "/sign-up",
        name: "SignUp",
        component: RegistrationComponent,
    },
    {
        path: "/store",
        name: "Store",
        component: StoreComponent,
        beforeEnter: () => {
            if(!localStorage.getItem('token')) {
                router.push('/login')
            }
        }
    },
    {
        path: "/store/:id",
        name: "Item Details",
        props: true,
        component: ItemDetailComponent,
        beforeEnter: () => {
            if(!localStorage.getItem('token')) {
                router.push('/login')
            }
        }
    },
    {
        path: "/profile",
        name: "Profile",
        props: true,
        component: ProfileComponent,
        beforeEnter: () => {
            if(!localStorage.getItem('token')) {
                router.push('/login')
            }
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;