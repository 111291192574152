<template>
    <div class="faq-wrap">
        <div class="container">
            <h2>Hello user!</h2>
            <h3>Watch the video on how to work in <span>Faster</span></h3>
            <div class="video">

            </div>
            <div class="text-video">
                If you need questions answered, you can see them below.
            </div>
        </div>
    </div>
    <div class="question-list">
        <div class="container">
            <div class="caption">
                Frequently Asked Questions
            </div>
            <v-expansion-panels>
                <v-expansion-panel
                        bg-color="transparent"
                        title="Are your projects done by designers in real time?"
                        text="No, all our projects are created in advance for the user’s unique tasks, they are created by existing designers in agencies and freelance."
                >
                </v-expansion-panel>
                <v-expansion-panel
                        bg-color="transparent"
                        title="Can I order a unique project with my technical specifications?"
                        text="We are working on introducing such a function into the user's personal account."
                >
                </v-expansion-panel>

                <v-expansion-panel
                        bg-color="transparent"
                        title="I paid for the project but nothing came, what should i do?"
                        text="The waiting time for the project is 3-5 minutes, after the rendering is completed, your project will appear in the RENDER LIST, you will see the status DONE and you will be able to download the finished creative."
                >
                </v-expansion-panel>
                <v-expansion-panel
                        bg-color="transparent"
                        title="I paid for the project but saw the message Error, what should I do?">
                    <v-expansion-panel-text>
                        You may have indicated incorrect or invalid characters in the TITLE, please check the correct spelling of your text. <br> <span class="orange">Note emoji and unique characters do not work.</span>
                    </v-expansion-panel-text>
                </v-expansion-panel>
                <v-expansion-panel
                        bg-color="transparent"
                        title="I uploaded a picture, it is larger or smaller than it is, what should i do?"
                >
                    <v-expansion-panel-text>
                        The recommendations for all creatives are currently identical: <br>
                        <b>The standard we use is 1080x1920 project size.</b> <br>
                        <span class="orange">The image is only in PNG format <br>
                    Picture standard: <br>
                    To get the best quality
                    1080x1080 and 900x1600 <br> </span>
                        Your image should not be larger than these parameters. <br>
                        You can check your image using this link: <span class="orange">https://www.posterburner.com/Image-Size-Finder.aspx   </span>

                    </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel
                        bg-color="transparent"
                        title="Can I upload the video in PNG format?"
                        text='No, video replacement is not available at this time.'>
                </v-expansion-panel>

                <v-expansion-panel
                        bg-color="transparent"
                        title="How can I contact support?"
                        text='You can write an email to support@faster.com'>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import httpService from "@/services/http.service.ts";

import {VExpansionPanels, VExpansionPanel, VExpansionPanelText} from "vuetify/components"

const vipList = ref(null);

onMounted(async () => {
    const vipResponse = await httpService.get('vip-status')
    console.log(vipList, '///')
    vipList.value = vipResponse.data.Vip;
})


</script>

<style scoped lang="scss">
.faq-wrap {
    h2 {
        text-align: center;
        font-size: 70px;
    }

    h3 {
        font-size: 30px;
        text-align: center;
        font-weight: normal;

        span {
            color: rgba(239, 137, 100, 1)
        }
    }

    background: rgba(217, 217, 217, 0.02);
    margin-top: 20px;

    .container {
        padding-top: 30px;
        background: none !important;
    }

    .video {
        height: 550px;
        border-radius: 50px;
        background: red;
        margin: 40px 0;
    }

    .text-video {
        padding-bottom: 80px;
        text-align: center;
        font-size: 30px;
    }
}

.caption {
    font-size: 70px;
    text-align: center;
    margin-bottom: 40px;
}

.question-list {
    background: rgba(217, 217, 217, 0.1);
    margin-bottom: 0;
    padding-bottom: 30px;
    margin-bottom: 40px;

    .orange {
        color: rgba(239, 137, 100, 1) !important;
        font-weight: bold;
    }

    .question {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .answer {
        font-weight: 300;
        font-size: 25px;
    }

    .container {
        padding-top: 50px;
        background: none !important;

    }
}

a {
    text-decoration: none;
}

.header-top {
    position: relative;
    color: #FAFAF9;
    margin-top: 100px;
    margin-bottom: 100px;

    .abs-img {
        top: 50px;
        right: 0;
        height: 800px;
        position: absolute;
    }

    .text-top {
        font-family: 'Abel', sans-serif;
        font-size: 150px;
        text-transform: uppercase;

        span {
            color: #EF8964;
        }
    }

    .text-desc {
        margin-bottom: 50px;
        font-size: 16px;
        font-weight: 200;
        color: rgba(239, 237, 232, 0.65);
    }
}

.not-sure {
    margin-top: 350px;
    display: flex;
    flex-direction: column;

    .not-sure-desc {
        color: #EFEDE8A6;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 53px;
    }

    .items {
        display: flex;
        justify-content: space-between;

        .item {
            width: 23%;
            background: #323443;
            border-radius: 12px;
            height: 190px;
            display: grid;
            padding: 25px;
            align-content: space-around;
            color: #fff;

            .title-item {
                padding-bottom: 10px;
                font-size: 16px;
                color: #FAFAF9;
            }

            .near {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .img {
                height: 20px;
            }


            .desc {
                color: rgba(239, 237, 232, 0.65);
                line-height: 24px;
                font-size: 14px;
            }

        }
    }
}

.useful-for {
    margin-top: 189px;
    display: flex;
    flex-direction: column;

    .item {
        border-radius: 12px;
        padding: 25px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {

            .title-item {
                line-height: 70px;
                font-size: 50px;
                width: 591px;
                color: white;
                margin-bottom: 48px;
            }

            .desc {
                line-height: 24px;
                font-size: 14px;
                color: rgba(239, 237, 232, 0.65);
                width: 480px;
                margin-bottom: 58px;
            }
        }

        .img {
            height: 510px;
        }

        .btn {
            display: flex;
            align-items: center;
            color: #0070C9;
        }

        .img-arrow {
            margin-left: 7px;
            height: 20px;
        }

        .near {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}

.price {

    .title-main {
        line-height: 70px;
        font-size: 50px;
        margin-top: 199px;
        color: white;
    }

    .items {
        display: flex;
        justify-content: space-around;
        margin-top: 94px;
        flex-wrap: wrap;

        .item {
            display: flex;
            margin-bottom: 20px;
            width: 538px;
            height: 640px;
            background: #232532;
            padding: 0 68px;
            align-items: center;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            ul {
                margin-top: 50px;
                margin-left: -60px;

                li {
                    line-height: 24px;
                    font-size: 18px;
                    color: rgba(239, 237, 232, 0.65);
                    letter-spacing: 0.5px;
                }
            }

            .title {
                line-height: 50px;
                font-size: 40px;
                text-transform: capitalize;
            }

            .price-n {
                font-size: 28px;
                font-weight: bold;
            }

            .desc {
                margin-top: 49px;
                line-height: 24px;
                width: 402px;
                font-size: 18px;
                color: rgba(239, 237, 232, 0.65);
            }


            .link-left {
                display: flex;
                color: #0070C9;
                margin-top: 124px;
                line-height: 26px;
                font-size: 16px;
                cursor: pointer;
                align-items: center;
                margin-right: auto;
            }

            .img-arrow {
                margin-left: 7px;
                height: 20px;
            }

            .link-right {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #0070C9;
                width: 150px;
                height: 30px;
                border-radius: 10px;
                line-height: 22px;
                font-size: 16px;
                color: white;
                margin-top: 103px;
                margin-left: -63%;
            }

        }

    }
}

.back-to {
    display: flex;
    justify-content: flex-end;

    .back {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #FAFAF9;
        width: 150px;
        height: 38px;
        line-height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        margin-top: 38px;
    }
}


</style>
