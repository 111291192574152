import axios from 'axios';

const httpService = axios.create({
    baseURL: 'https://fastcreo.com/api/v1/',
    headers: {
        "Content-Type": "application/json",
    }
});
const getAuthToken = () => localStorage.getItem('token');

const authInterceptor = (config) => {
    config.headers['Authorization'] = 'Bearer ' + getAuthToken();
    return config;
}
if (getAuthToken) {
    httpService.interceptors.request.use(authInterceptor);
}

httpService.interceptors.response.use(
    response => response,
    (error)=> {
        return error
    }
);
export default httpService;