<template>
    <div class="login">
        <v-card elevation="4" light tag="section">
            <v-card-title>
                Sign up
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field
                            outline
                            label="Name"
                            type="text"
                            v-model="name"
                    ></v-text-field>
                    <v-text-field
                            outline
                            label="Email"
                            type="text"
                            v-model="email"
                    ></v-text-field>
                    <v-text-field
                            outline
                            label="Password"
                            type="password"
                            v-model="password"
                    ></v-text-field>
                    <v-text-field
                            outline
                            label="Confirm password"
                            type="password"
                            v-model="confirmPassword"
                    ></v-text-field>

                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="white" @click="login()">
                    Sign up
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script setup>
import {VCardTitle, VCard, VCardText, VBtn, VTextField, VSpacer, VDivider, VCardActions} from "vuetify/components"
import UserService from "../services/user.service.ts";
import {ref} from "vue";
import router from "@/router";

const name = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
import {useStateStore} from "@/store/state.ts";
import * as notification from "@kyvg/vue3-notification";

const stateStore = useStateStore();
const login = async () => {
    const data = {name: name.value,email: email.value, password: password.value,c_password: confirmPassword.value}
    await UserService.signUp(data).then((res) => {
        if (res) {
            if(res.response && res.response.data.message) {
                notification.notify({
                    title: res.response.data.message,
                    type: "error",
                    duration: 7000
                });
            }else {
                localStorage.setItem('token', res.data.data.token)
                stateStore.setAuthStatus(true)
                router.push('/store')
                console.log('322')
            }


        } else {
            console.log('123')
            alert('Issue with login')
        }
    })
}

</script>

<style scoped>
.login {
    height: 100%;
    margin: 200px auto;
    width: 50%;

    .v-card {
        background: rgb(48, 48, 48) !important;
        color: #fff !important
    }
}
</style>
