<template>
    <div>
        <v-card class="item-detail container">
            <div class="left">
                <v-card-title> Render creative</v-card-title>
                <v-card-subtitle>{{ currentProject.title }} - {{ currentProject.description }}</v-card-subtitle>
                <div class="fields">
                    <div class="field" v-for="(item, index) in currentProject.fields" :key="index">
                        <v-text-field :label="`Title ${index+1} (Max length - ${item} symbols)`"
                                      :maxlength="item"
                                      v-model="fields[index]"
                        >
                        </v-text-field>
                    </div>
                    <div class="field" v-if="currentProject.isNeedImage">
                        <v-file-input
                                label="Load image"
                                type="file"
                                @change="onFileChanged($event)"
                        ></v-file-input>
                    </div>

                </div>
                <v-card-actions v-if="stateStore.isAuthenticated">
                    <v-spacer></v-spacer>
                    <v-btn variant="outlined" @click="generate()">
                        Render
                    </v-btn>
                </v-card-actions>
            </div>
            <div class="right">
                <div class="example">Example</div>
                <iframe
                        :src="currentProject.projectLink">
                </iframe>
            </div>
        </v-card>
        <v-card class="active-tasks container" v-if="stateStore.isAuthenticated && user && user.tasks.length > 0">
            <v-card-title>
                Render List
            </v-card-title>
            <div class="item" :class="item.link_project ? 'done' : 'at-work'" v-for="item of user.tasks" :key="item.nr_project">
                <div class="name">
                    {{ item.title }} #{{ item.nr_project_task}}{{format(item.created_at, "ddMM")}}{{item.nr_project}}
                    <span>
                        Last render:
                        {{ format(item.created_at, "HH:mm (dd.MM)") }}
                    </span>
                </div>

                <a :href="item.link_project" target="_blank" class="status" v-if="item.link_project">
                    Done
                    <v-icon end
                            icon="mdi-cloud-download"
                    ></v-icon>
                </a>

                <div class="status" v-if="!item.link_project">
                    Progress
                </div>

            </div>
        </v-card>


    </div>


</template>
<script setup>
// VFileInput
import {VCard, VIcon, VSpacer, VCardActions, VBtn, VCardTitle, VCardSubtitle, VFileInput, VTextField} from "vuetify/components"
import {onMounted, ref} from "vue";
import httpService from "@/services/http.service.ts";
import {useRoute} from "vue-router";
import {useStateStore} from "@/store/state.ts";
import {format} from "date-fns";
import * as notification from "@kyvg/vue3-notification";

const stateStore = useStateStore();

const currentProject = ref('');
const fields = ref([]);
const user = ref('');
const image = ref();
const route = useRoute()

onMounted(async () => {
    const res = await httpService.get(`projects/${route.params.id}`)
    currentProject.value = res.data.data;

    if (stateStore.isAuthenticated) {
        await getUserInfo();
    }
})

async function getUserInfo() {
    const userResponse = await httpService.get(`user?include-tasks=true`)
    //123
    user.value = userResponse.data.data[0]
}


async function generate() {
    const formData = new FormData();
    formData.append('id_user', user.value.id,);
    formData.append('id_task', route.params.id);
    for (let i = 0; i < fields.value.length; i++) {
        if (fields.value[i]) {
            formData.append('text' + (i + 1), fields.value[i]);
        }
    }
    if (currentProject.value.isNeedImage) {
        formData.append('file_path', image.value);
    }

    try {
        await httpService.post(`project-tasks`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            fields.value = []
            console.log(res, 'qwe', res.data.paymentLink)
            if(res.data && res.data.paymentLink) {
                notification.notify({
                    title: "First you need to pay for creative or plan.",
                    type: "warn"
                });
                setTimeout(()=> {
                    window.location.href = res.data.paymentLink;
                },2000)
            }else {
                notification.notify({
                    title: res.data,
                    duration: 7000,
                    type: "success"
                });
            }

            getUserInfo();
        })
    } catch (ex) {
        notification.notify({
            title: "Contact support team",
            type: "error"
        });
    }
}

function onFileChanged(e) {
    const target = e.target;
    if (target && target.files) {
        image.value = target.files[0];
    }
}

</script>

<style scoped>


.active-tasks {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;

    .item {
        background: #2c2c2c;
        padding: 15px;
        margin-bottom: 10px;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .name {
            display: flex;
            flex-direction: column;

            span {
                font-size: 12px;
            }
        }

        &.at-work {
            .status {
                color: #F2C94C;
                text-decoration: none;
            }
        }

        &.done {
            .status {
                text-decoration: none;
                color: #27AE60;
            }
        }
    }
}

.item-detail {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .v-card {
        padding: 10px;
        display: flex;
    }

    .v-card-title, .v-card-subtitle {
        padding-top: 0;
        padding-bottom: 10px;
        line-height: 1em;
        padding-left: 0;
    }

    .left {
        padding-right: 20px;
        width: 70%;

        .fields {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;

            .field {
                width: 49%;
            }
        }
    }

    .right {
        width: 30%;

        .example {
            font-size: 1.25rem;
            padding-bottom: 10px;
        }
    }
}
</style>
