<template>
  <footer v-if="homePage">
      <div>
          IP Ivanenko Artem Genadie <br>
          OGRNIP 323220200029621 <br>
          INN 226300907159
      </div>
  </footer>
</template>

<script setup>
import {onMounted, ref} from "vue";
import { useRoute } from 'vue-router';

const homePage = ref(false)
const route = useRoute()

onMounted(async () => {
    homePage.value = route.name === "Home"
})
</script>

<style scoped lang="scss">
footer {
    color:#fff;
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    margin: 0 auto;
    max-width: 1300px;
    opacity: 0.3;
    font-size: 12px;
}
</style>
