<template>
    <div class="login">
        <v-card elevation="4" light tag="section">
            <v-card-text>
                <p class="mb-2">Sign in with your username and password:</p>
                <v-form>
                    <v-text-field
                            outline
                            label="Username"
                            type="text"
                            v-model="email"
                            ></v-text-field>
                    <v-text-field
                            outline
                            hide-details
                            label="Password"
                            type="password"
                            v-model="password"
                            ></v-text-field>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="white" flat @click="reg()">
                    Dont have account?
                    <div class="span"> Sign Up</div>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="white" @click="login()">
                    Login
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script setup>
import { VCard, VCardText, VBtn, VTextField, VSpacer, VDivider, VCardActions} from "vuetify/components"
import UserService from "../services/user.service.ts";
import {ref} from "vue";
import router from "@/router";

const email = ref('');
const password = ref('');
import {useStateStore} from "@/store/state.ts";
const stateStore = useStateStore();
const login = async ()=> {
    const data = {email:email.value, password: password.value}
    await UserService.login(data).then((res)=> {
        if(res) {
            localStorage.setItem('token', res.data.data.token)
            stateStore.setAuthStatus(true)
            router.push('/store')
        }else {
            alert('Issue with login')
        }
    });
}

function reg() {
    router.push('/sign-up')
}

</script>

<style scoped>
.login {
    height: 100%;
    margin: 200px auto;
    width: 50%;
    .v-card {
        background: rgb(48,48,48) !important;
        color: #fff !important
    }
}
</style>
