import {defineStore} from 'pinia'
import {computed, ref} from 'vue';

export const useStateStore = defineStore("state", () => {
    const isAuthenticated = ref(!!localStorage.getItem('token'));

    function setAuthStatus(val) {
        isAuthenticated.value = val;
    }

    return {
        setAuthStatus,
        isAuthenticated: computed(() => {
            return isAuthenticated.value
        })
    };
});