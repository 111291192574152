import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './main.css'
import { createPinia } from 'pinia'
import Vuetify from './plugins/vuetify'
import Notifications from '@kyvg/vue3-notification'

const pinia = createPinia()
createApp(App).use(Vuetify).use(pinia).use(Notifications).use(router).mount('#app');


