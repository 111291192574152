<template>
  <header>
      <div class="logo">
          <router-link to="/" class="item">Faster</router-link>
      </div>
      <div class="menu">
<!--          <div class="item">dashboard</div>-->
<!--          <div class="item">about</div>-->
          <router-link to="/" class="item">Home</router-link>
          <router-link to="/store" class="item">Store</router-link>
          <router-link to="/profile" class="item" v-if="stateStore.isAuthenticated">Profile</router-link>
          <router-link to="/faq" class="item">Faq</router-link>
          <router-link to="/login" class="item" v-if="!stateStore.isAuthenticated">Login</router-link>
          <router-link to="/sign-up" class="item" v-if="!stateStore.isAuthenticated">New Account</router-link>
          <div class="item btn" v-if="stateStore.isAuthenticated" @click="logout()">Logout</div>
      </div>
  </header>
</template>

<script setup>

import {useStateStore} from "@/store/state.ts";
const stateStore = useStateStore();

function logout() {
    stateStore.setAuthStatus(false);
    localStorage.removeItem('token')
}

</script>

<style scoped lang="scss">
header {
    color:#fff;
    width: 1300px;
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    align-items: center;
    .logo {
        a {
            color: #fff;
            text-decoration: none;
            font-size: 20px;
            font-weight: bold;
        }
    }
    .menu {
        display: flex;
        align-items: center;
        .item {
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            margin-left: 15px;
            color: #fff;
            text-decoration: none;
            &.btn {
                background: #EF8964;
                padding: 8px;
            }
        }
    }
}
</style>
