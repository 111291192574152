<template>
    <div class="store container">
        <div class="filters">
            <div class="search">
                <input type="text" v-model="searchValue" placeholder="Search...">
            </div>
            <div class="item" :class="{'active': selectedCategory === ''}" @click="filterByCategory('')">All</div>
            <div class="item" v-for="category of categoryList" :class="{'active': selectedCategory === category, 'order1': category === 'New'}" :key="category" @click="filterByCategory(category)">{{category}}</div>
        </div>
        <div class="right">
            <div v-if="itemsListResponse && itemsListResponse.response && itemsListResponse.response.data.verification_message" class="center-text">
                {{itemsListResponse.response.data.verification_message}}
            </div>
            <div class="items" v-if="itemsList">
                <v-card class="item" v-for="item of itemsList" :key="item" @click="goToProject(item.id)"
                        :color="'gray'">
                    <v-img
                            aspect-ratio="16/9"
                            cover
                            :src="item.image"
                    ></v-img>
                    <div class="new" v-if="item.isNew">
                        New
                    </div>
                    <v-card-actions class="color-actions">
                        <div class="name-creative">
                            {{ item.title }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn color="info">
                            View
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
            <v-pagination v-model="currentPage" @update:modelValue="onPageChange" v-if="pagination" :length="pagination.last_page"></v-pagination>
        </div>
    </div>
</template>
<script setup>
import {VSpacer, VBtn, VCard, VImg, VCardActions,VPagination} from "vuetify/components"
import httpService from "@/services/http.service.ts";
import {onMounted, ref, watch} from "vue";
import router from "@/router";
import * as notification from "@kyvg/vue3-notification";
import debounce from 'lodash.debounce'

const itemsList = ref();
const currentPage = ref(1);
const pagination = ref();
const itemsListResponse = ref();
const categoryList = ref();
const selectedCategory = ref('');
const searchValue = ref('');

onMounted(async () => {
    itemsListResponse.value = await httpService.get('projects')
    const category = await httpService.get('project-types')
    if(itemsListResponse.value.data) {
        itemsList.value = itemsListResponse.value.data.data;
        pagination.value = itemsListResponse.value.data.meta
    }
    categoryList.value = category.data.category;
})

async function filterByCategory(category) {
    if(selectedCategory.value === category) {
        selectedCategory.value = ''
    }else {
        selectedCategory.value = category;
    }
    itemsListResponse.value = await httpService.get('projects?category[eq]='+selectedCategory.value)
    itemsList.value = itemsListResponse.value.data.data;
    pagination.value = itemsListResponse.value.data.meta
    currentPage.value = 1;
}

async function filterByTitle() {
    itemsListResponse.value = await httpService.get('projects?title[eq]='+searchValue.value)
    itemsList.value = itemsListResponse.value.data.data;
    pagination.value = itemsListResponse.value.data.meta
    currentPage.value = 1;
}

const update = debounce(() => {
    filterByTitle();
}, 500)

watch(searchValue, () => {
    update();
})


async function onPageChange(page) {
    currentPage.value = page;

    let query = ''
    if(selectedCategory.value !== '') {
        query = '&category[eq]='+ selectedCategory.value
    }
    itemsListResponse.value = await httpService.get('projects?page='+page+query)
    itemsList.value = itemsListResponse.value.data.data;
    pagination.value = itemsListResponse.value.data.meta

}

function goToProject(id) {
    if(!id) {
        notification.notify({
            title: "Subscription plan is required",
            type: "warn"
        });
        return
    }
    router.push({name: 'Item Details', params: {id: id}})
}


</script>

<style scoped>
.order1 {
    order: -1 !important;
}
.v-alert {
    position: fixed;
    top: 40px;
    right: 15px;

}
.store {
    .center-text {
        text-align: center;
        margin: 20px;
    }
    .color-actions {
        background: #171717;
        color: #fff;
    }

    .filters {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        position: relative;
        .item {
            order: 0;
            cursor: pointer;
            color: #a8a6a3;
            padding: 0 10px;
            text-transform: capitalize;
            &.active {
                color: #F2C94C;
            }

        }

        .search {
            position: absolute;
            left: 0;
            background: black;
            border-radius: 20px;
            width: 300px;
            padding: 2px 10px;
            input {
                width: 100%;
                padding: 4px;
                outline: none;
                color: #fff;
                &::-webkit-input-placeholder {
                    color: #fff;
                    opacity: 1; /* Firefox */
                }
            }
        }
    }

    background: #fff;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .left {
        width: 17%;

        .list {
            display: flex;
            flex-direction: column;
        }

        .v-card-title {
            padding-left: 0;
        }
    }

    .right {
        min-height: 50px;
        .items {
            margin-top: 20px;
            flex-wrap: wrap;
            display: flex;
            width: 100%;

            .v-card {
                margin-bottom: 30px;
            }

            .item {
                width: 18%;
                margin-right: 2%;
                background: #171717;
                position: relative;
                .new {
                    background: #303030;
                    position: absolute;
                    left: 0;
                    top: 0;
                    color: #F2C94C;
                    padding: 5px 8px;
                    font-weight: bold;
                }
                &:nth-child(5n) {
                    margin-right: 0;
                }
            }
        }

        width: 100%;
    }
}
</style>
