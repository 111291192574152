// @ts-ignore
import HttpService from "./http.service.ts";
class UserService {
    login(data){
        return HttpService.post('login',data)
    }

    signUp(data){
        return HttpService.post('register',data)
    }
}

export default new UserService();